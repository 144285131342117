var video = function () {
    // Seleciona os elementos
    var openVideoButtons = document.querySelectorAll('.open-video');
    var closeVideoButton = document.getElementById('close-video');
    var videoOverlay = document.getElementById('video-overlay');
    var videoPlaceholder = document.getElementById('video-placeholder');
    // Função para abrir o vídeo
    openVideoButtons.forEach(function (button) {
        button.addEventListener('click', function (evt) {
            evt.preventDefault();
            var videoId = button.getAttribute('data-video'); // Obtém o ID do vídeo do atributo data-video
            if (videoOverlay) {
                videoOverlay.classList.add('active'); // Ativa o overlay
            }
            // Limpa qualquer iframe existente antes de criar um novo
            if (videoPlaceholder) {
                videoPlaceholder.innerHTML = ''; // Remove o iframe anterior, se houver
            }
            // Verifica se o ID do vídeo é válido
            if (videoId) {
                var youtubeIframe = document.createElement('iframe');
                youtubeIframe.width = "560";
                youtubeIframe.height = "315";
                youtubeIframe.src = "https://www.youtube.com/embed/".concat(videoId, "?autoplay=1&enablejsapi=1");
                youtubeIframe.frameBorder = "0";
                youtubeIframe.allow = "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture";
                youtubeIframe.allowFullscreen = true;
                // Adiciona o iframe ao placeholder
                if (videoPlaceholder) {
                    videoPlaceholder.appendChild(youtubeIframe);
                }
            }
        });
    });
    // Função para fechar o vídeo
    if (closeVideoButton) {
        closeVideoButton.addEventListener('click', closeVideo);
    }
    // Função para fechar o vídeo ao clicar no overlay
    if (videoOverlay) {
        videoOverlay.addEventListener('click', function (e) {
            // Verifica se o clique foi fora do container do vídeo
            if (e.target === videoOverlay) {
                closeVideo();
            }
        });
    }
    // Função que remove o vídeo e fecha o modal
    function closeVideo() {
        if (videoOverlay) {
            videoOverlay.classList.remove('active'); // Remove o overlay
        }
        if (videoPlaceholder) {
            videoPlaceholder.innerHTML = ''; // Remove o iframe quando o modal é fechado
        }
    }
};
export default video;
